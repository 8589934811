<template>
  <div
    v-click-outside="{
      exclude: ['dropdown'],
      handler: 'handlerClose',
    }"
    ref="dropdown"
    class="categories-dropdown"
  >
    <div class="categories-dropdown__header" @click="isOpen = !isOpen">
      <div class="categories-dropdown__title" style="margin-left: 10px">
        {{ header }}
      </div>
      <sm-icon
        class="categories-dropdown__toggle-icon"
        name="plus"
        outline
        :stroke-width="3"
      />
    </div>
    <ul v-show="isOpen" class="categories-dropdown__list">
      <div class="categories-dropdown__header" @click="isOpen = !isOpen">
        <div class="categories-dropdown__title" style="margin-right: 10px">
          {{ header }}
        </div>
        <sm-icon
          class="categories-dropdown__toggle-icon"
          name="times"
          outline
          :stroke-width="3"
        />
      </div>
      <template v-if="items.length">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="categories-dropdown__item"
        >
          <router-link class="categories-dropdown__link" :to="item.route">{{
            item.name
          }}</router-link>
        </li>
      </template>
      <p v-else class="categories-dropdown__alert-text">
        Вы еще не посетили ни одной страницы
      </p>
    </ul>
  </div>
</template>

<script>
import SmIcon from '@/components/common/SmIcon.vue';
export default {
  name: 'SmCategoriesDropdown',

  components: {
    SmIcon,
  },

  props: {
    header: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    handlerClose() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.categories-dropdown {
  position: relative;

  width: 230px;
  height: auto;
  padding: 10px;
  margin-left: auto;

  cursor: pointer;
}

.categories-dropdown__header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.categories-dropdown__title {
  font-size: 18px;
  font-weight: 400;
}

.categories-dropdown__toggle-icon {
  pointer-events: none;
}

.categories-dropdown__list {
  position: absolute;
  top: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 10px;

  background-color: var(--white);
  border-radius: 10px;

  box-shadow: 0 0 4px rgba(var(--rgb-black), 0.25);
}

.categories-dropdown__item {
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.categories-dropdown__link {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray);

  &:hover {
    color: var(--blue);
  }
}

.categories-dropdown__alert-text {
  font-size: 14px;
  color: var(--gray);
}
</style>
